import * as React from 'react';
import { Grid, Cell } from 'baseui/layout-grid';
import { useStyletron } from 'baseui';
import {
  LabelMedium,
  LabelSmall,
  HeadingXSmall,
  ParagraphSmall,
} from 'baseui/typography';
import Button from '../styledButton';
import { Input } from 'baseui/input';
import { StatefulPopover, PLACEMENT } from 'baseui/popover';
import { SketchPicker } from 'react-color';
import { useMutation } from '@apollo/client';
import gql from 'graphql-tag';
import TextArea from '../channel/textarea';
import HelpQuestionMark from '../help/questionMarkHelp';
import ImageUploadCXL from '../imageUploaderCXL';
import { sourceByType } from '../../util';
import { organizationLogo } from '../help/imageSizeGuidelines.json';
import { videoCoverPhoto } from '../help/imageSizeGuidelines.json';
import { showAlert } from '../../redux/actions/appBasicControls';
import { Select } from "baseui/select";
import {
  Checkbox,
  LABEL_PLACEMENT
} from "baseui/checkbox";

import {
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  ModalButton,
  SIZE,
  ROLE
} from "baseui/modal";
import { KIND as ButtonKind } from "baseui/button";
import JSONInput from 'react-json-editor-ajrm';
import locale from 'react-json-editor-ajrm/locale/en';
import { Accordion, Panel } from "baseui/accordion";

import {
  admin_invitation_help,
  app_user_invitation_help,
  admin_welcome_help,
  color_palette_help,
  settings_logo_help,
  settings_video_cover_help,
  email_sender_configuration,
  confirmation_text_help
} from '../help/helpTexts';
import { Textarea } from 'baseui/textarea';

const getSrc = data => sourceByType(data, 'small');

const gridPaddingOverrides = {
  Grid: {
    style: ({ $theme }) => ({
      paddingLeft: '0px !important',
      paddingRight: '0px !important',
      paddingTop: '0px !important',
      paddingBottom: '0px !important',
      marginBottom: $theme.sizing.scale550,
    }),
  },
};

const cellPaddingOverrides = {
  Cell: {
    style: () => ({
      // paddingLeft: '0px !important' ,
      // paddingRight: '0px !important' ,
      // paddingTop: '0px !important' ,
      // paddingBottom: '0px !important'
    }),
  },
};

const GeneralSettings = ({ organization, dispatch, refetch }) => {
  const [css, theme] = useStyletron();

  const [name, setName] = React.useState(organization.name || '');
  const [color_palette, setPalette] = React.useState(
    organization.color_palette || []
  );
  const [
    admin_invitation_subject,
    set_admin_invitation_subject,
  ] = React.useState(organization.admin_invitation_subject || '');

  const [admin_invitation_copy, set_admin_invitation_copy] = React.useState(
    organization.admin_invitation_copy || ''
  );

  const [
    app_user_invitation_copy,
    set_app_user_invitation_copy,
  ] = React.useState(organization.app_user_invitation_copy || '');

  const [
    app_user_invitation_subject,
    set_app_user_invitation_subject,
  ] = React.useState(organization.app_user_invitation_subject || '');

  const [
    admin_activation_email_subject,
    set_admin_activation_email_subject,
  ] = React.useState(organization.admin_activation_email_subject || '');
  const [
    admin_activation_email_body,
    set_admin_activation_email_body,
  ] = React.useState(organization.admin_activation_email_body || '');

  const [
    app_user_activation_email_subject,
    set_app_user_activation_email_subject,
  ] = React.useState(organization.app_user_activation_email_subject || '');

  const [
    app_user_activation_email_body,
    set_app_user_activation_email_body,
  ] = React.useState(organization.app_user_activation_email_body || '');

  const [
    admin_invitation_email_bcc,
    set_admin_invitation_email_bcc,
  ] = React.useState(organization.admin_invitation_email_bcc || '');

  const [
    admin_invitation_email_attach_url,
    set_admin_invitation_email_attach_url,
  ] = React.useState(organization.admin_invitation_email_attach_url || '');
  const [
    admin_invitation_email_attach_button_text,
    set_admin_invitation_email_attach_button_text,
  ] = React.useState(organization.admin_invitation_email_attach_button_text || '');
  const [
    app_user_invitation_email_bcc,
    set_app_user_invitation_email_bcc,
  ] = React.useState(organization.app_user_invitation_email_bcc || '');
  const [
    app_user_invitation_email_attach_url,
    set_app_user_invitation_email_attach_url,
  ] = React.useState(organization.app_user_invitation_email_attach_url || '');
  const [
    app_user_invitation_email_attach_button_text,
    set_app_user_invitation_email_attach_button_text,
  ] = React.useState(organization.app_user_invitation_email_attach_button_text || '');
  const [
    admin_activation_email_bcc,
    set_admin_activation_email_bcc,
  ] = React.useState(organization.admin_activation_email_bcc || '');
  const [
    admin_activation_email_attach_url,
    set_admin_activation_email_attach_url,
  ] = React.useState(organization.admin_activation_email_attach_url || '');
  const [
    admin_activation_email_attach_button_text,
    set_admin_activation_email_attach_button_text,
  ] = React.useState(organization.admin_activation_email_attach_button_text || '');
  const [
    app_user_activation_email_bcc,
    set_app_user_activation_email_bcc,
  ] = React.useState(organization.app_user_activation_email_bcc || '');
  const [
    app_user_activation_email_attach_url,
    set_app_user_activation_email_attach_url,
  ] = React.useState(organization.app_user_activation_email_attach_url || '');
  const [
    app_user_activation_email_attach_button_text,
    set_app_user_activation_email_attach_button_text,
  ] = React.useState(organization.app_user_activation_email_attach_button_text || '');


  const [
    admin_email_welcome_template,
    set_admin_email_welcome_template,
  ] = React.useState(organization.admin_email_welcome_template || '');
  const [
    admin_email_welcome_subject,
    set_admin_email_welcome_subject,
  ] = React.useState(organization.admin_email_welcome_subject || '');

  const [logo_data, set_logo_data] = React.useState(organization.logo_data || null);
  const [video_cover_photo_data, set_video_cover_photo_data] = React.useState(organization.video_cover_photo_data || null);
  const [video_cover_photo, set_video_cover_photo] = React.useState(null);
  const [logo, set_logo] = React.useState(null);
  const [preivew_image, setPreview] = React.useState(null);
  const [video_cover_image, setVideoCover] = React.useState(null);
  const [domain, setDomain] = React.useState(organization.domain.domain);

  const [isAddNewLoading, setAddNewLoading] = React.useState(false);

  const [web_portal_url, set_web_portal_url] = React.useState(organization.web_portal_url || '');
  const [ios_app_url, set_ios_app_url] = React.useState(organization.ios_app_url || '');
  const [android_app_url, set_android_app_url] = React.useState(organization.android_app_url || '');
  const [specifications_filter_query_rule, set_specifications_filter_query_rule] = React.useState(organization.specifications_filter_query_rule || 'or');
  const [tags_filter_query_rule, set_tags_filter_query_rule] = React.useState(organization.tags_filter_query_rule || 'or');
  const [chat_users_source, set_chat_users_source] = React.useState(organization.chat_users_source || JSON.stringify(['AppUsers']));
  const [validate_crm_on_login, set_validate_crm_on_login] = React.useState(organization.validate_crm_on_login || false);
  const [filters_ordering, set_filters_ordering] = React.useState(organization.filters_ordering || 'default');

  const [privacy_url, set_privacy_url] = React.useState(organization.privacy_url || '');
  const [terms_url, set_terms_url] = React.useState(organization.terms_url || '');

  const [dark_logo, set_dark_logo] = React.useState(null);
  const [dark_logo_data, set_dark_logo_data] = React.useState(organization.dark_logo_data || '');
  const [invite_email_background_color, set_invite_email_background_color] = React.useState(organization.invite_email_background_color || '#fff,#fff,#fff');
  const [mail_sender_config_from_email, set_mail_sender_config_from_email] = React.useState(organization.mail_sender_config_from_email || '');
  const [mail_sender_config_from_name, set_mail_sender_config_from_name] = React.useState(organization.mail_sender_config_from_name || '');
  const [email_template, set_email_template] = React.useState(organization.email_template || 'v1');

  // const [is_api_settings_on, set_is_api_settings_on] = React.useState(organization.is_api_settings_on || false);
  // const [is_library_nav_on, set_is_library_nav_on] = React.useState(organization.is_library_nav_on || false);
  // const [is_rewards_nav_on, set_is_rewards_nav_on] = React.useState(organization.is_rewards_nav_on || false);

  const [requires_biometric_auth, set_requires_biometric_auth] = React.useState(organization.requires_biometric_auth || false);
  const [is_super_org, set_is_super_org] = React.useState(organization.is_super_org || false);
  const [languages, set_languages] = React.useState(organization.languages || '{}');
  const [external_tag, set_external_tag] = React.useState(organization.external_tag || '');
  const [crm_user_points, set_crm_user_points] = React.useState(organization.crm_user_points || '0');
  const [auto_create_cardware_crm_user, set_auto_create_cardware_crm_user] = React.useState(organization.auto_create_cardware_crm_user || false);

  const [isEmailModalOpen, setIsEmailModalOpen] = React.useState(false)
  const [isEmailLoading, setIsEmailLoading] = React.useState(false)
  const [email, setEmail] = React.useState('')
  const [subject, setSubject] = React.useState('')
  const [body, setBody] = React.useState('');

  const [confirmation_text, setConfirmationText] = React.useState(organization.confirmation_text || '');
  const [investment_form_amounts, set_investment_form_amounts] = React.useState(organization.investment_form_amounts || '');
  const [is_locations_available_for_signup, set_is_locations_available_for_signup] = React.useState(organization.is_locations_available_for_signup || false);
  const investment_form_amounts_formatted = investment_form_amounts ? JSON.parse(investment_form_amounts) : [];
  const [dark_preview_image, set_dark_preview_image] = React.useState(null);

  const [tags, setTags] = React.useState([]);
  React.useEffect(() => {
    fetch('https://lma-hub-crm-api-staging-19e08234e99c.herokuapp.com/user_crm/organizations')
    .then((response) => {
      return response.json()
    })
    .then((data) => {
      let t = [];
      data.forEach(el => {
        try {
          t = [...t, ...(el.externalTags.lma || [])];
          setTags(t);
        } catch(e) {

        }
      })
    })
  }, []);

  const setDarkPreview = (file) => {
    if (!file) set_dark_preview_image(null);
    set_dark_preview_image(URL.createObjectURL(file))
  };

  const previewImage = file => {
    setPreview(URL.createObjectURL(file));
  };
  const preViewVideoCover = file => {
    if (!file) setVideoCover(null);
    setVideoCover(URL.createObjectURL(file));
  };

  const [sendEmail, { isLoading, emailResponse }] = useMutation(gql`
    mutation sendEmail(
      $organization_id: ID!
      $email: String!
      $subject: String!
      $body: String!
      $current_admin_location: String
    ) {
      sendEmail(
        organization_id: $organization_id
        email: $email
        subject: $subject
        body: $body
        current_admin_location: $current_admin_location
      )
    }
  `);

  const onSendEmail = async () => {
    if (!subject || !body) {
      return dispatch(showAlert({
        msg: 'Please input subject & template fields',
        error: true,
      }))
    }
    setIsEmailLoading(true)
    await sendEmail({
      variables: {
        organization_id: organization.id,
        email,
        subject,
        body,
        current_admin_location: window.location.origin
      },
    });
    onCloseEmailTest()
    dispatch(
      showAlert({
        msg: 'Successfully sent an email!',
        error: false,
      })
    );
  }

  const onOpen = () => {
    setIsEmailModalOpen(true)
  }

  const onCloseEmailTest = () => {
    setIsEmailModalOpen(false)
    setEmail('')
    setSubject('')
    setBody('')
    setIsEmailLoading(false)
  }

  const onChangeConfirmationText = (e) => {
    setConfirmationText(e.target.value)
  }

  const onSave = () => {
    setAddNewLoading(true);
    updateOrganization({
      variables: {
        id: organization.id,
        color_palette,
        name,
        admin_invitation_subject,
        admin_invitation_copy,
        app_user_invitation_copy,
        app_user_invitation_subject,
        admin_email_welcome_subject,
        admin_email_welcome_template,
        logo_data,
        logo,
        domain,
        web_portal_url,
        is_locations_available_for_signup,
        ios_app_url,
        android_app_url,
        specifications_filter_query_rule,
        tags_filter_query_rule,
        chat_users_source,
        validate_crm_on_login,
        filters_ordering,
        privacy_url,
        terms_url,
        // is_api_settings_on,
        // is_library_nav_on,
        // is_rewards_nav_on,
        video_cover_photo_data,
        video_cover_photo,
        dark_logo,
        dark_logo_data,
        requires_biometric_auth,
        is_super_org,
        external_tag,
        auto_create_cardware_crm_user,
        crm_user_points,
        languages,
        invite_email_background_color,
        mail_sender_config_from_name,
        mail_sender_config_from_email,
        email_template,
        admin_activation_email_subject,
        admin_activation_email_body,
        app_user_activation_email_subject,
        app_user_activation_email_body,
        admin_invitation_email_bcc,
        admin_invitation_email_attach_url,
        admin_invitation_email_attach_button_text,
        app_user_invitation_email_bcc,
        app_user_invitation_email_attach_url,
        app_user_invitation_email_attach_button_text,
        admin_activation_email_bcc,
        admin_activation_email_attach_url,
        admin_activation_email_attach_button_text,
        app_user_activation_email_bcc,
        app_user_activation_email_attach_url,
        app_user_activation_email_attach_button_text,
        confirmation_text,
        investment_form_amounts
      },
    });
  };

  const [updateOrganization, { loading, data }] = useMutation(gql`
    mutation updateOrganization(
      $id: ID!
      $color_palette: [String!]
      $name: String
      $admin_invitation_subject: String
      $admin_invitation_copy: String
      $admin_email_welcome_subject: String
      $admin_email_welcome_template: String
      $app_user_invitation_copy: String
      $app_user_invitation_subject: String
      $logo_data: String
      $logo: Upload
      $domain: String
      $web_portal_url: String,
      $is_locations_available_for_signup: Boolean
      $ios_app_url: String,
      $android_app_url: String
      $specifications_filter_query_rule: String
      $tags_filter_query_rule: String
      $chat_users_source: String
      $validate_crm_on_login: Boolean
      $filters_ordering: String
      $privacy_url: String
      $terms_url: String
      $video_cover_photo_data: String
      $video_cover_photo: Upload
      $dark_logo: Upload
      $dark_logo_data: String
      $requires_biometric_auth: Boolean
      $is_super_org: Boolean
      $external_tag: String
      $crm_user_points: String
      $auto_create_cardware_crm_user: Boolean
      $languages: String
      $invite_email_background_color: String
      $mail_sender_config_from_email: String
      $mail_sender_config_from_name: String  
      $is_api_settings_on: Boolean
      $is_library_nav_on: Boolean
      $is_rewards_nav_on: Boolean
      $email_template: String
      $admin_activation_email_subject: String
      $admin_activation_email_body: String
      $app_user_activation_email_subject: String
      $app_user_activation_email_body: String
      $admin_invitation_email_bcc: String
      $admin_invitation_email_attach_url: String
      $admin_invitation_email_attach_button_text: String
      $app_user_invitation_email_bcc: String
      $app_user_invitation_email_attach_url: String
      $app_user_invitation_email_attach_button_text: String
      $admin_activation_email_bcc: String
      $admin_activation_email_attach_url: String
      $admin_activation_email_attach_button_text: String
      $app_user_activation_email_bcc: String
      $app_user_activation_email_attach_url: String
      $app_user_activation_email_attach_button_text: String
      $confirmation_text: String
      $investment_form_amounts: String
    ) {
      updateOrganization(
        id: $id
        color_palette: $color_palette
        name: $name
        admin_invitation_subject: $admin_invitation_subject
        admin_invitation_copy: $admin_invitation_copy
        app_user_invitation_copy: $app_user_invitation_copy
        admin_email_welcome_subject: $admin_email_welcome_subject
        admin_email_welcome_template: $admin_email_welcome_template
        app_user_invitation_subject: $app_user_invitation_subject
        logo_data: $logo_data
        logo: $logo
        domain: $domain
        web_portal_url: $web_portal_url
        is_locations_available_for_signup: $is_locations_available_for_signup
        ios_app_url: $ios_app_url
        android_app_url: $android_app_url
        specifications_filter_query_rule: $specifications_filter_query_rule
        tags_filter_query_rule: $tags_filter_query_rule
        chat_users_source: $chat_users_source
        validate_crm_on_login: $validate_crm_on_login
        filters_ordering: $filters_ordering
        privacy_url: $privacy_url
        terms_url: $terms_url
        video_cover_photo_data: $video_cover_photo_data
        video_cover_photo: $video_cover_photo
        dark_logo: $dark_logo
        dark_logo_data: $dark_logo_data
        requires_biometric_auth: $requires_biometric_auth
        is_super_org: $is_super_org
        external_tag: $external_tag
        languages: $languages
        invite_email_background_color: $invite_email_background_color
        mail_sender_config_from_email: $mail_sender_config_from_email
        mail_sender_config_from_name: $mail_sender_config_from_name  
        is_api_settings_on: $is_api_settings_on
        is_library_nav_on: $is_library_nav_on
        is_rewards_nav_on: $is_rewards_nav_on
        email_template: $email_template
        admin_activation_email_subject: $admin_activation_email_subject
        admin_activation_email_body: $admin_activation_email_body
        app_user_activation_email_subject: $app_user_activation_email_subject
        app_user_activation_email_body: $app_user_activation_email_body
        admin_invitation_email_bcc: $admin_invitation_email_bcc
        admin_invitation_email_attach_url: $admin_invitation_email_attach_url
        admin_invitation_email_attach_button_text: $admin_invitation_email_attach_button_text
        app_user_invitation_email_bcc: $app_user_invitation_email_bcc
        app_user_invitation_email_attach_url: $app_user_invitation_email_attach_url
        app_user_invitation_email_attach_button_text: $app_user_invitation_email_attach_button_text
        admin_activation_email_bcc: $admin_activation_email_bcc
        admin_activation_email_attach_url: $admin_activation_email_attach_url
        admin_activation_email_attach_button_text: $admin_activation_email_attach_button_text
        app_user_activation_email_bcc: $app_user_activation_email_bcc
        app_user_activation_email_attach_url: $app_user_activation_email_attach_url
        app_user_activation_email_attach_button_text: $app_user_activation_email_attach_button_text
        confirmation_text: $confirmation_text
        auto_create_cardware_crm_user: $auto_create_cardware_crm_user
        crm_user_points: $crm_user_points
        investment_form_amounts: $investment_form_amounts
      ) {
        name
        filters_ordering
        privacy_url
        terms_url
        is_api_settings_on
        is_library_nav_on
        is_rewards_nav_on
        email_template
      }
    }
  `);

  React.useEffect(() => {
    if (loading === false && isAddNewLoading === true) {
      setAddNewLoading(false);
      refetch();
    }
    // if (data) {
    //   data = data.updateOrganization;
    // }
  }, [data]);
  console.log('investment_form_amounts_formattedinvestment_form_amounts_formatted', investment_form_amounts_formatted);
  let chat_users_source_parsed;
  try {
    chat_users_source_parsed = JSON.parse(chat_users_source);
  } catch (err) {
    chat_users_source_parsed = ['AppUsers']
  };

  const addChatSource = (type, status) => {
    if (status) {
      chat_users_source_parsed.push(type);
    } else {
      chat_users_source_parsed = chat_users_source_parsed.filter(el => el != type);
    }
    chat_users_source_parsed = Array.from(new Set(chat_users_source_parsed));
    set_chat_users_source(JSON.stringify(chat_users_source_parsed));
  };

  const getParsedLanguages = (ln) => {
    try {
      return JSON.parse(ln);
    } catch (e) {
      return {};
    }
  }

  return (
    <Grid overrides={gridPaddingOverrides}>
      <Cell overrides={cellPaddingOverrides} span={12} align={'right'}>
        <div
          className={css({
            position: 'relative',
            float: 'right',
            marginBottom: '16px',
          })}
        >
          <Button
            isSelected
            isLoading={isAddNewLoading}
            onClick={() => {
              onSave();
            }}
          >
            Save
          </Button>
        </div>
      </Cell>
      <Cell overrides={cellPaddingOverrides} span={[12, 6, 4]}>
        {/* NAME */}
        <div
          className={css({
            padding: '24px',
            background: theme.colors.primaryB,
            marginBottom: '24px',
          })}
        >
          <LabelMedium
            style={{
              marginBottom: '16px',
            }}
          >
            Name
          </LabelMedium>
          <Input
            value={name}
            onChange={e => setName(e.target.value)}
            placeholder="Name of organization"
            clearOnEscape
          />
        </div>

        <div
          className={css({
            padding: '24px',
            background: theme.colors.primaryB,
            marginBottom: '24px',
          })}
        >
          <LabelMedium
            style={{
              marginBottom: '16px',
            }}
          >
            Domain
          </LabelMedium>
          <Input
            value={domain}
            onChange={e => setDomain(e.target.value)}
            placeholder="Domain of organization"
            clearOnEscape
          />
        </div>

        {/* COLOR PALETTE */}
        <div
          className={css({
            padding: '24px',
            background: theme.colors.primaryB,
            marginBottom: '24px',
          })}
        >
          <div
            className={css({
              display: 'flex',
              marginBottom: '16px',
            })}
          >
            <LabelMedium style={{}}>Color Palette</LabelMedium>
            <HelpQuestionMark
              text={color_palette_help}
              embedCss={{ marginTop: 0 }}
            ></HelpQuestionMark>
          </div>
          <div
            className={css({
              background: theme.colors.backgroundTertiary,
              padding: '16px',
              height: '132px',
              // display: 'flex'
            })}
          >
            {color_palette.map((color, index) => {
              return (
                <StatefulPopover
                  content={() => (
                    <SketchPicker
                      color={color}
                      onChangeComplete={color => {
                        let newP = JSON.parse(JSON.stringify(color_palette));
                        newP[index] = color.hex;
                        setPalette(newP);
                      }}
                    />
                  )}
                  placement={PLACEMENT['bottomRight']}
                  returnFocus
                  autoFocus
                  overrides={{
                    Body: {
                      style: ({ $theme }) => ({
                        marginTop: '50px',
                      }),
                    },
                  }}
                >
                  <div
                    key={color}
                    className={css({
                      marginRight: '16px',
                      marginBottom: '16px',
                      width: 'calc((100% - 96px - 32px) / 7)',
                      height: '24px',
                      position: 'relative',
                      float: 'left',
                      borderRadius: '4px',
                    })}
                    style={{
                      background: color,
                    }}
                  ></div>
                </StatefulPopover>
              );
            })}
          </div>
        </div>

        {/* Admin User Invite */}
        <div
          className={css({
            padding: '24px',
            background: theme.colors.primaryB,
            marginBottom: '24px',
          })}
        >
          <div
            className={css({
              display: 'flex',
            })}
          >
            <HeadingXSmall
              style={{
                marginBottom: '16px',
              }}
            >
              Admin Invitation Copy
            </HeadingXSmall>
            <HelpQuestionMark text={admin_invitation_help}></HelpQuestionMark>
          </div>
          <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
            <LabelMedium style={{ marginBottom: '1rem', marginTop: '1rem', }}>Subject</LabelMedium>
            <Button onClick={() => {
              setSubject(admin_invitation_subject)
              setBody(admin_invitation_copy)
              onOpen()
            }} isSelected marginTop={'0px'}>Send Test Email</Button>
          </div>
          <Input
            value={admin_invitation_subject}
            onChange={e => set_admin_invitation_subject(e.target.value)}
            placeholder="Subject"
            clearOnEscape
          />
          <LabelMedium
            style={{
              marginBottom: '16px',
              marginTop: '16px',
            }}
          >
            Body
          </LabelMedium>
          {/* <Input
          value={admin_invitation_copy}
          onChange={e => set_admin_invitation_copy(e.target.value)}
          placeholder="Body"
          clearOnEscape
        /> */}
          <TextArea
            placeholder="Body"
            value={admin_invitation_copy}
            onChange={set_admin_invitation_copy}
            textAreaKey="admin_invitation_copy"
          ></TextArea>
          <div>
            <LabelMedium
              style={{
                marginBottom: '16px',
                marginTop: '16px',
              }}
            >
              BCC
            </LabelMedium>
            <Input
              value={admin_invitation_email_bcc}
              onChange={e => set_admin_invitation_email_bcc(e.target.value)}
              placeholder="BCC (email1, email2, emailn"
              clearOnEscape
            />
          </div>
          <div>
            <LabelMedium
              style={{
                marginBottom: '16px',
                marginTop: '16px',
              }}
            >
              Attachment URL
            </LabelMedium>
            <Input
              value={admin_invitation_email_attach_url}
              onChange={e => set_admin_invitation_email_attach_url(e.target.value)}
              placeholder="Attachment URL"
              clearOnEscape
            />
          </div>
          <div>
            <LabelMedium
              style={{
                marginBottom: '16px',
                marginTop: '16px',
              }}
            >
              Attachment Button Text
            </LabelMedium>
            <Input
              value={admin_invitation_email_attach_button_text}
              onChange={e => set_admin_invitation_email_attach_button_text(e.target.value)}
              placeholder="Attachment Button Text"
              clearOnEscape
            />
          </div>
          <div
            className={css({
              display: 'flex',
              marginTop: '24px',
            })}
          >
            <HeadingXSmall
              style={{
                marginBottom: '16px',
              }}
            >
              App User Invitation Copy
            </HeadingXSmall>
            <HelpQuestionMark
              text={app_user_invitation_help}
            ></HelpQuestionMark>
          </div>
          <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
            <LabelMedium style={{ marginBottom: '1rem', marginTop: '1rem', }}>Subject</LabelMedium>
            <Button onClick={() => {
              setSubject(app_user_invitation_subject)
              setBody(app_user_invitation_copy)
              onOpen()
            }} isSelected marginTop={'0px'}>Send Test Email</Button>
          </div>
          <Input
            value={app_user_invitation_subject}
            onChange={e => set_app_user_invitation_subject(e.target.value)}
            placeholder="Subject"
            clearOnEscape
          />
          <LabelMedium
            style={{
              marginBottom: '16px',
              marginTop: '16px',
            }}
          >
            Body
          </LabelMedium>
          {/* <Input
          value={app_user_invitation_copy}
          onChange={e => set_app_user_invitation_copy(e.target.value)}
          placeholder="Body"
          clearOnEscape
        /> */}
          <TextArea
            placeholder="Body"
            value={app_user_invitation_copy}
            onChange={set_app_user_invitation_copy}
            textAreaKey="app_user_invitation_copy"
          ></TextArea>
          <div>
            <LabelMedium
              style={{
                marginBottom: '16px',
                marginTop: '16px',
              }}
            >
              BCC
            </LabelMedium>
            <Input
              value={app_user_invitation_email_bcc}
              onChange={e => set_app_user_invitation_email_bcc(e.target.value)}
              placeholder="BCC (email1, email2, emailn"
              clearOnEscape
            />
          </div>
          <div>
            <LabelMedium
              style={{
                marginBottom: '16px',
                marginTop: '16px',
              }}
            >
              Attachment URL
            </LabelMedium>
            <Input
              value={app_user_invitation_email_attach_url}
              onChange={e => set_app_user_invitation_email_attach_url(e.target.value)}
              placeholder="Attachment URL"
              clearOnEscape
            />
          </div>
          <div>
            <LabelMedium
              style={{
                marginBottom: '16px',
                marginTop: '16px',
              }}
            >
              Attachment Button Text
            </LabelMedium>
            <Input
              value={app_user_invitation_email_attach_button_text}
              onChange={e => set_app_user_invitation_email_attach_button_text(e.target.value)}
              placeholder="Attachment Button Text"
              clearOnEscape
            />
          </div>
        </div>
        {/* //Admin User Activation */}
        <div
          className={css({
            padding: '24px',
            background: theme.colors.primaryB,
            marginBottom: '24px',
          })}
        >
          <div
            className={css({
              display: 'flex',
            })}
          >
            <HeadingXSmall
              style={{
                marginBottom: '16px',
              }}
            >
              Admin Activation Copy
            </HeadingXSmall>
            <HelpQuestionMark text={admin_invitation_help}></HelpQuestionMark>
          </div>
          <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
            <LabelMedium style={{ marginBottom: '1rem', marginTop: '1rem', }}>Subject</LabelMedium>
            <Button onClick={() => {
              setSubject(admin_activation_email_subject)
              setBody(admin_activation_email_body)
              onOpen()
            }} isSelected marginTop={'0px'}>Send Test Email</Button>
          </div>
          <Input
            value={admin_activation_email_subject}
            onChange={e => set_admin_activation_email_subject(e.target.value)}
            placeholder="Subject"
            clearOnEscape
          />
          <LabelMedium
            style={{
              marginBottom: '16px',
              marginTop: '16px',
            }}
          >
            Body
          </LabelMedium>
          {/* <Input
          value={admin_invitation_copy}
          onChange={e => set_admin_invitation_copy(e.target.value)}
          placeholder="Body"
          clearOnEscape
        /> */}
          <TextArea
            placeholder="Body"
            value={admin_activation_email_body}
            onChange={set_admin_activation_email_body}
            textAreaKey="admin_activation_email_body"
          ></TextArea>
          <div>
            <LabelMedium
              style={{
                marginBottom: '16px',
                marginTop: '16px',
              }}
            >
              BCC
            </LabelMedium>
            <Input
              value={admin_activation_email_bcc}
              onChange={e => set_admin_activation_email_bcc(e.target.value)}
              placeholder="BCC (email1, email2, emailn"
              clearOnEscape
            />
          </div>

          <div>
            <LabelMedium
              style={{
                marginBottom: '16px',
                marginTop: '16px',
              }}
            >
              Attachment URL
            </LabelMedium>
            <Input
              value={admin_activation_email_attach_url}
              onChange={e => set_admin_activation_email_attach_url(e.target.value)}
              placeholder="Attachment URL"
              clearOnEscape
            />
          </div>

          <div>
            <LabelMedium
              style={{
                marginBottom: '16px',
                marginTop: '16px',
              }}
            >
              Attachment Button Text
            </LabelMedium>
            <Input
              value={admin_activation_email_attach_button_text}
              onChange={e => set_admin_activation_email_attach_button_text(e.target.value)}
              placeholder="Attachment Button Text"
              clearOnEscape
            />
          </div>

          <div
            className={css({
              display: 'flex',
              marginTop: '24px',
            })}
          >
            <HeadingXSmall
              style={{
                marginBottom: '16px',
              }}
            >
              App User Activation Copy
            </HeadingXSmall>
            <HelpQuestionMark
              text={admin_invitation_help}
            ></HelpQuestionMark>
          </div>
          <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
            <LabelMedium style={{ marginBottom: '1rem', marginTop: '1rem', }}>Subject</LabelMedium>
            <Button onClick={() => {
              setSubject(app_user_activation_email_subject)
              setBody(app_user_activation_email_body)
              onOpen()
            }} isSelected marginTop={'0px'}>Send Test Email</Button>
          </div>
          <Input
            value={app_user_activation_email_subject}
            onChange={e => set_app_user_activation_email_subject(e.target.value)}
            placeholder="Subject"
            clearOnEscape
          />
          <LabelMedium
            style={{
              marginBottom: '16px',
              marginTop: '16px',
            }}
          >
            Body
          </LabelMedium>
          {/* <Input
          value={app_user_invitation_copy}
          onChange={e => set_app_user_invitation_copy(e.target.value)}
          placeholder="Body"
          clearOnEscape
        /> */}
          <TextArea
            placeholder="Body"
            value={app_user_activation_email_body}
            onChange={set_app_user_activation_email_body}
            textAreaKey="app_user_activation_email_body"
          ></TextArea>

          <div>
            <LabelMedium
              style={{
                marginBottom: '16px',
                marginTop: '16px',
              }}
            >
              BCC
            </LabelMedium>
            <Input
              value={app_user_activation_email_bcc}
              onChange={e => set_app_user_activation_email_bcc(e.target.value)}
              placeholder="BCC (email1, email2, emailn"
              clearOnEscape
            />
          </div>

          <div>
            <LabelMedium
              style={{
                marginBottom: '16px',
                marginTop: '16px',
              }}
            >
              Attachment URL
            </LabelMedium>
            <Input
              value={app_user_activation_email_attach_url}
              onChange={e => set_app_user_activation_email_attach_url(e.target.value)}
              placeholder="Attachment URL"
              clearOnEscape
            />
          </div>

          <div>
            <LabelMedium
              style={{
                marginBottom: '16px',
                marginTop: '16px',
              }}
            >
              Attachment Button Text
            </LabelMedium>
            <Input
              value={app_user_activation_email_attach_button_text}
              onChange={e => set_app_user_activation_email_attach_button_text(e.target.value)}
              placeholder="Attachment Button Text"
              clearOnEscape
            />
          </div>

        </div>

        <div
          className={css({
            padding: '24px',
            background: theme.colors.primaryB,
            marginBottom: '24px',
          })}
        >
          <div
            className={css({
              display: 'flex',
            })}
          >
            <HeadingXSmall
              style={{
                marginBottom: '16px',
              }}
            >
              Email Sender Configuration
            </HeadingXSmall>
            <HelpQuestionMark text={email_sender_configuration}></HelpQuestionMark>
          </div>
          <LabelMedium
            style={{
              marginBottom: '16px',
            }}
          >
            Email
          </LabelMedium>
          <Input
            value={mail_sender_config_from_email}
            onChange={e => set_mail_sender_config_from_email(e.target.value)}
            placeholder="email of sender"
            clearOnEscape
          />
          <LabelMedium
            style={{
              marginBottom: '16px',
              marginTop: '16px',
            }}
          >
            Name
          </LabelMedium>
          <Input
            value={mail_sender_config_from_name}
            onChange={e => set_mail_sender_config_from_name(e.target.value)}
            placeholder="name of sender"
            clearOnEscape
          />
        </div>

      </Cell>
      <Cell overrides={cellPaddingOverrides} span={[12, 6, 4]}>
        <div
          className={css({
            padding: '24px',
            background: theme.colors.primaryB,
            marginBottom: '24px',
          })}
        >
          <LabelMedium
            style={{
              marginBottom: '16px',
            }}
          >
            Web Portal URL
          </LabelMedium>
          <Input
            value={web_portal_url}
            onChange={e => set_web_portal_url(e.target.value)}
            placeholder="Enter Web portal Url"
            clearOnEscape
          />
        </div>
        <div
          className={css({
            padding: '24px',
            background: theme.colors.primaryB,
            marginBottom: '24px',
          })}
        >
          <LabelMedium
            style={{
              marginBottom: '16px',
            }}
          >
            IOS App URL
          </LabelMedium>
          <Input
            value={ios_app_url}
            onChange={e => set_ios_app_url(e.target.value)}
            placeholder="Enter IOS App Url"
            clearOnEscape
          />
        </div>
        <div
          className={css({
            padding: '24px',
            background: theme.colors.primaryB,
            marginBottom: '24px',
          })}
        >
          <LabelMedium
            style={{
              marginBottom: '16px',
            }}
          >
            Android App URL
          </LabelMedium>
          <Input
            value={android_app_url}
            onChange={e => set_android_app_url(e.target.value)}
            placeholder="Enter Android App Url"
            clearOnEscape
          />
        </div>
        <div
          className={css({
            padding: '24px',
            background: theme.colors.primaryB,
            marginBottom: '24px',
          })}
        >
          <LabelMedium
            style={{
              marginBottom: '16px',
            }}
          >
            Specifications Query Rule
          </LabelMedium>
          <Select
            options={[
              { label: "OR Rule (More Data)", id: "or" },
              { label: "AND Rule (Less Data)", id: "and" },
            ]}
            value={[
              { label: "OR Rule (More Data)", id: "or" },
              { label: "AND Rule (Less Data)", id: "and" },
            ].filter((el) => el.id == specifications_filter_query_rule)}
            placeholder="Select specifications query rule"
            onChange={params => {
              if (params && params.option) {
                set_specifications_filter_query_rule(params.option.id);
              }
            }}
          />
        </div>
        <div
          className={css({
            padding: '24px',
            background: theme.colors.primaryB,
            marginBottom: '24px',
          })}
        >
          <LabelMedium
            style={{
              marginBottom: '16px',
            }}
          >
            Tags Query Rule
          </LabelMedium>
          <Select
            options={[
              { label: "OR Rule (More Data)", id: "or" },
              { label: "AND Rule (Less Data)", id: "and" },
            ]}
            value={[
              { label: "OR Rule (More Data)", id: "or" },
              { label: "AND Rule (Less Data)", id: "and" },
            ].filter((el) => el.id == tags_filter_query_rule)}
            placeholder="Select specifications query rule"
            onChange={params => {
              if (params && params.option) {
                set_tags_filter_query_rule(params.option.id);
              }
            }}
          />
        </div>
        <div
          className={css({
            padding: '24px',
            background: theme.colors.primaryB,
            marginBottom: '24px',
          })}
        >
          <LabelMedium
            style={{
              marginBottom: '16px',
            }}
          >
            Email Template
          </LabelMedium>
          <Select
            options={[
              { label: "Template Version 1", id: "v1" },
              { label: "Template Version 2", id: "v2" },
              { label: "Template Version 3 (Plain Text)", id: "v3" },
            ]}
            value={[
              { label: "Template Version 1", id: "v1" },
              { label: "Template Version 2", id: "v2" },
              { label: "Template Version 3 (Plain Text)", id: "v3" },
            ].filter((el) => el.id == email_template)}
            placeholder="Select specifications query rule"
            onChange={params => {
              if (params && params.option) {
                set_email_template(params.option.id);
              }
            }}
          />
        </div>
        <div
          className={css({
            padding: '24px',
            background: theme.colors.primaryB,
            marginBottom: '24px',
          })}
        >
          <LabelMedium
            style={{
              marginBottom: '16px',
            }}
          >
            Filters Ordering
          </LabelMedium>
          <Select
            options={[
              { label: "Default ", id: "default" },
              { label: "Alphabetical", id: "alphabetical" },
            ]}
            value={[
              { label: "Default ", id: "default" },
              { label: "Alphabetical", id: "alphabetical" },
            ].filter((el) => el.id == filters_ordering)}
            placeholder="Select specifications query rule"
            onChange={params => {
              if (params && params.option) {
                set_filters_ordering(params.option.id);
              }
            }}
          />
        </div>

        <div
          className={css({
            padding: '24px',
            background: theme.colors.primaryB,
            marginBottom: '24px',
          })}
        >
          <LabelMedium
            style={{
              marginBottom: '16px',
            }}
          >
            Location & CRM Filters
          </LabelMedium>
          <Checkbox
            checked={is_locations_available_for_signup}
            onChange={e => set_is_locations_available_for_signup(e.target.checked)}
            labelPlacement={LABEL_PLACEMENT.right}
          >
            Locations available for sign up?
          </Checkbox>
          <Checkbox
            checked={validate_crm_on_login}
            onChange={e => set_validate_crm_on_login(e.target.checked)}
            labelPlacement={LABEL_PLACEMENT.right}
          >
            Validate CRM on login?
          </Checkbox>
        </div>

        <div
          className={css({
            padding: '24px',
            background: theme.colors.primaryB,
            marginBottom: '24px',
          })}
        >
          <LabelMedium
            style={{
              marginBottom: '16px',
            }}
          >
            Available users for chat
          </LabelMedium>
          <Checkbox
            checked={chat_users_source_parsed.includes('AppUsers')}
            onChange={e => {
              addChatSource('AppUsers', e.target.checked);
            }}
            labelPlacement={LABEL_PLACEMENT.right}
          >
            App Users
          </Checkbox>
          <Checkbox
            checked={chat_users_source_parsed.includes('Admins')}
            onChange={e => addChatSource('Admins', e.target.checked)}
            labelPlacement={LABEL_PLACEMENT.right}
          >
            Admins
          </Checkbox>
          <Checkbox
            checked={chat_users_source_parsed.includes('Trainers')}
            onChange={e => addChatSource('Trainers', e.target.checked)}
            labelPlacement={LABEL_PLACEMENT.right}
          >
            Trainers
          </Checkbox>
        </div>

        <div
          className={css({
            padding: '24px',
            background: theme.colors.primaryB,
            marginBottom: '24px',
          })}
        >
          <LabelMedium
            style={{
              marginBottom: '16px',
            }}
          >
            Privacy URL
          </LabelMedium>
          <Input
            value={privacy_url}
            onChange={e => set_privacy_url(e.target.value)}
            placeholder="Enter Privacy Url"
            clearOnEscape
          />
          <LabelMedium
            style={{
              marginBottom: '16px',
              marginTop: '16px',
            }}
          >
            Terms URL
          </LabelMedium>
          <Input
            value={terms_url}
            onChange={e => set_terms_url(e.target.value)}
            placeholder="Enter Terms Url"
            clearOnEscape
          />
        </div>

        <div
          className={css({
            padding: '24px',
            background: theme.colors.primaryB,
            marginBottom: '24px',
          })}
        >
          <LabelMedium
            style={{
              marginBottom: '0px',
            }}
          >
            Invite Email Template Background Color
          </LabelMedium>
          <ParagraphSmall
            style={{
              marginBottom: '16px',
            }}
          >
            These three colors will set in email template in same order. You can see sample email template <a href="https://storage.googleapis.com/catalystxl-admin-api/graph/full/YUooA70-h-Screenshot_2021-07-28_at_8.38.03_PM.png" target="_blank">here</a>.
          </ParagraphSmall>
          <div
            className={css({
              background: theme.colors.backgroundTertiary,
              padding: '16px',
              height: '65px',
              // display: 'flex'
            })}
          >
            {invite_email_background_color.split(',').map(color => color.trim()).map((color, index) => {
              return (
                <StatefulPopover
                  content={() => (
                    <SketchPicker
                      color={color}
                      onChangeComplete={color => {
                        let newP = JSON.parse(JSON.stringify(invite_email_background_color.split(',')));
                        newP[index] = color.hex;
                        set_invite_email_background_color(newP.toString());
                      }}
                    />
                  )}
                  placement={PLACEMENT['bottomRight']}
                  returnFocus
                  autoFocus
                  overrides={{
                    Body: {
                      style: ({ $theme }) => ({
                        marginTop: '50px',
                      }),
                    },
                  }}
                >
                  <div
                    key={color}
                    className={css({
                      marginRight: '16px',
                      marginBottom: '16px',
                      width: 'calc((100% - 96px - 32px) / 7)',
                      height: '24px',
                      position: 'relative',
                      float: 'left',
                      borderRadius: '4px',
                      border: '1px solid'
                    })}
                    style={{
                      background: color,
                    }}
                  ></div>
                </StatefulPopover>
              );
            })}
          </div>
        </div>

        {/* Admin User Welcome */}
        <div
          className={css({
            padding: '24px',
            background: theme.colors.primaryB,
            marginBottom: '24px',
          })}
        >
          <div
            className={css({
              display: 'flex',
            })}
          >
            <HeadingXSmall
              style={{
                marginBottom: '16px',
              }}
            >
              Admin Welcome Email Template
            </HeadingXSmall>
            <HelpQuestionMark text={admin_welcome_help}></HelpQuestionMark>
          </div>
          <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
            <LabelMedium style={{ marginBottom: '1rem', marginTop: '1rem', }}>Subject</LabelMedium>
            <Button onClick={() => {
              setSubject(admin_email_welcome_subject)
              setBody(admin_email_welcome_template)
              onOpen()
            }} isSelected marginTop={'0px'}>Send Test Email</Button>
          </div>
          <Input
            value={admin_email_welcome_subject}
            onChange={e => set_admin_email_welcome_subject(e.target.value)}
            placeholder="Email Subject"
            clearOnEscape
          />
          <LabelMedium
            style={{
              marginBottom: '16px',
              marginTop: '16px',
            }}
          >
            Body
          </LabelMedium>
          <TextArea
            placeholder="Email Body"
            value={admin_email_welcome_template}
            onChange={set_admin_email_welcome_template}
            textAreaKey="admin_email_welcome_template"
          ></TextArea>
        </div>

        {/* Admin User Welcome */}
        <div
          className={css({
            padding: '24px',
            background: theme.colors.primaryB,
            marginBottom: '24px',
          })}
        >
          <div
            className={css({
              display: 'flex',
            })}
          >
            <HeadingXSmall
              style={{
                marginBottom: '16px',
              }}
            >
              Form confirmation text
            </HeadingXSmall>
            <HelpQuestionMark text={confirmation_text_help}></HelpQuestionMark>
          </div>
          <LabelMedium
            style={{
              marginBottom: '16px',
              marginTop: '16px',
            }}
          >
            Text
          </LabelMedium>
          {/* <TextArea
            placeholder="Body"
            value={confirmation_text}
            onChange={setConfirmationText}
            textAreaKey="confirmation_text"
          ></TextArea> */}
          <Textarea
            placeholder="Body"
            value={confirmation_text}
            onChange={onChangeConfirmationText}
            key="confirmation_text"
          />
        </div>
        {/* <div
          className={css({
            padding: '24px',
            background: theme.colors.primaryB,
            marginBottom: '24px',
          })}
        >
          <LabelMedium
            style={{
              marginBottom: '0px',
            }}
          >
            Control Settings
          </LabelMedium>
          <ParagraphSmall
            style={{
              marginBottom: '16px',
            }}
          >
            Turn on and off different navigation and builder settings.
          </ParagraphSmall>
          <div>
            <Checkbox
              checked={is_api_settings_on}
              onChange={e => set_is_api_settings_on(e.target.checked)}
              labelPlacement={LABEL_PLACEMENT.right}
            >
              Api
            </Checkbox>
          </div>
          <div>
            <Checkbox
              checked={is_rewards_nav_on}
              onChange={e => set_is_rewards_nav_on(e.target.checked)}
              labelPlacement={LABEL_PLACEMENT.right}
            >
              Rewards
            </Checkbox>
          </div>
          <div>
            <Checkbox
              checked={is_library_nav_on}
              onChange={e => set_is_library_nav_on(e.target.checked)}
              labelPlacement={LABEL_PLACEMENT.right}
            >
              Library
            </Checkbox>
          </div>
        </div> */}
      </Cell>
      <Cell overrides={cellPaddingOverrides} span={[12, 6, 4]}>
        <div
          className={css({
            paddingBottom: '24px',
            paddingLeft: '24px',
            paddingRight: '24px',
            paddingTop: '1px',
            background: theme.colors.primaryB,
            marginBottom: '24px',
          })}
        >
          <ImageUploadCXL
            title="Logo"
            titleHelpText={settings_logo_help}
            previewUrls={preivew_image || getSrc(logo_data)}
            isMultiple={false}
            onDrop={(acceptedFiles, rejectedFiles) => {
              set_logo(acceptedFiles[0]);
              previewImage(acceptedFiles[0]);
            }}
            onImageDelete={() => {
              set_logo(null);
              set_logo_data(null);
              setPreview(null);
            }}
            imageSize={organizationLogo.size}
            imageSizeGuidelineURL={organizationLogo.url}
          ></ImageUploadCXL>
          <ParagraphSmall
            style={{
              marginTop: '16px',
            }}
          >
            This logo is also used at top of the email template. You can see sample email template <a href="https://storage.googleapis.com/catalystxl-admin-api/graph/full/YUooA70-h-Screenshot_2021-07-28_at_8.38.03_PM.png" target="_blank">here</a>.
          </ParagraphSmall>
        </div>
        <div
          className={css({
            paddingBottom: '24px',
            paddingLeft: '24px',
            paddingRight: '24px',
            paddingTop: '1px',
            background: theme.colors.primaryB,
            marginBottom: '24px',
          })}
        >
          <ImageUploadCXL
            title="Email Logo"
            titleHelpText={settings_logo_help}
            previewUrls={dark_preview_image || getSrc(dark_logo_data)}
            isMultiple={false}
            onDrop={(acceptedFiles, rejectedFiles) => {
              set_dark_logo(acceptedFiles[0]);
              setDarkPreview(acceptedFiles[0]);
            }}
            onImageDelete={() => {
              set_dark_logo(null);
              set_dark_logo_data(null);
              setDarkPreview(null);
            }}
            imageSize={organizationLogo.size}
            imageSizeGuidelineURL={organizationLogo.url}
          ></ImageUploadCXL>
          <ParagraphSmall
            style={{
              marginTop: '16px',
            }}
          >
            This logo is used in the email template. You can see sample email template <a href="https://storage.googleapis.com/catalystxl-admin-api/graph/full/YUooA70-h-Screenshot_2021-07-28_at_8.38.03_PM.png" target="_blank">here</a>.
          </ParagraphSmall>
        </div>
        <div
          className={css({
            paddingBottom: '24px',
            paddingLeft: '24px',
            paddingRight: '24px',
            paddingTop: '1px',
            background: theme.colors.primaryB,
            marginBottom: '24px',
          })}
        >
          <ImageUploadCXL
            title="Video Cover Photo"
            titleHelpText={settings_video_cover_help}
            previewUrls={video_cover_image || getSrc(video_cover_photo_data)}
            isMultiple={false}
            onDrop={(selectedVideoCover, rejectedVideoCover) => {
              set_video_cover_photo(selectedVideoCover[0]);
              preViewVideoCover(selectedVideoCover[0]);
            }}
            onImageDelete={() => {
              set_video_cover_photo(null);
              set_video_cover_photo_data(null);
              preViewVideoCover(null);
            }}
            imageSize={videoCoverPhoto.size}
            imageSizeGuidelineURL={videoCoverPhoto.url}
          ></ImageUploadCXL>
        </div>
        <div
          className={css({
            padding: '24px',
            background: theme.colors.primaryB,
            marginBottom: '24px',
          })}
        >
          <LabelMedium
            style={{
              marginBottom: '16px',
            }}
          >
            App Security
          </LabelMedium>
          <Checkbox
            checked={requires_biometric_auth}
            onChange={e => set_requires_biometric_auth(e.target.checked)}
            labelPlacement={LABEL_PLACEMENT.right}
          >
            App requires biometric auth to open?
          </Checkbox>
        </div>
        <div
          className={css({
            padding: '24px',
            background: theme.colors.primaryB,
            marginBottom: '24px',
          })}
        >
          <LabelMedium
            style={{
              marginBottom: '16px',
            }}
          >
            Organization Config
          </LabelMedium>
          <Checkbox
            checked={is_super_org}
            onChange={e => set_is_super_org(e.target.checked)}
            labelPlacement={LABEL_PLACEMENT.right}
          >
            Super Organization?
          </Checkbox>
          <LabelMedium
            style={{
              marginBottom: '16px',
              marginTop: '16px'
            }}
          >
            External Tag Org Mapping
          </LabelMedium>
          <Select
            options={tags.map(el => {
              return {
                label: el.name,
                id: el.id
              }
            })}
            value={tags.map(el => {
              return {
                label: el.name,
                id: el.id
              }
            }).filter((el) => el.id == external_tag)}
            placeholder="Select External Org Tag"
            onChange={params => {
              if (params && params.option) {
                set_external_tag(params.option.id);
              }
            }}
          />
        </div>
        <div
          className={css({
            padding: '24px',
            background: theme.colors.primaryB,
            marginBottom: '24px',
          })}
        >
          <LabelMedium
            style={{
              marginBottom: '16px',
            }}
          >
            CRM Settings
          </LabelMedium>
          <Checkbox
            checked={auto_create_cardware_crm_user}
            onChange={e => set_auto_create_cardware_crm_user(e.target.checked)}
            labelPlacement={LABEL_PLACEMENT.right}
          >
            Auto create crm user?
          </Checkbox>
          <LabelMedium
            style={{
              marginBottom: '16px',
              marginTop: '16px',
            }}
          >
            Base Wallet Amount
          </LabelMedium>
          <Input
            value={crm_user_points}
            onChange={e => set_crm_user_points(e.target.value)}
            placeholder="Base Wallet Amount"
            clearOnEscape
          />
        </div>
        <div
          className={css({
            padding: '24px',
            background: theme.colors.primaryB,
            marginBottom: '24px',
          })}
        >
          <LabelMedium
            style={{
              marginBottom: '16px',
            }}
          >
            App Languages
          </LabelMedium>
          {/* <Checkbox
            checked={requires_biometric_auth}
            onChange={e => set_requires_biometric_auth(e.target.checked)}
            labelPlacement={LABEL_PLACEMENT.right}
          >
            App requires biometric auth to open?
          </Checkbox> */}
          <Accordion
            overrides={{
              Root: {
                style: ({ $theme }) => ({
                  marginLeft: 0,
                })
              }
            }}
          >
            <Panel title="Add app languages">
              <JSONInput
                id='a_unique_id'
                placeholder={getParsedLanguages(languages)}
                theme="dark_vscode_tribute"
                colors={{
                  string: "#DAA520" // overrides theme colors with whatever color value you want
                }}
                locale={locale}
                height='550px'
                width={'60vw'}
                onChange={e => {
                  // console.log(e);
                  !e.error && set_languages(JSON.stringify(e.jsObject));
                }}
              />
            </Panel>
          </Accordion>
        </div>
        <div
          className={css({
            padding: '24px',
            background: theme.colors.primaryB,
            marginBottom: '24px',
          })}
        >
          <LabelMedium
            style={{
              marginBottom: '16px',
            }}
          >
            Form Investment Amounts
          </LabelMedium>
          {/* <Checkbox
            checked={requires_biometric_auth}
            onChange={e => set_requires_biometric_auth(e.target.checked)}
            labelPlacement={LABEL_PLACEMENT.right}
          >
            App requires biometric auth to open?
          </Checkbox> */}
          <Accordion
            overrides={{
              Root: {
                style: ({ $theme }) => ({
                  marginLeft: 0,
                })
              }
            }}
          >
            <Panel title="Form Investment Amounts">
              <JSONInput
                id='a_unique_id__1'
                placeholder={investment_form_amounts_formatted}
                theme="dark_vscode_tribute"
                colors={{
                  string: "#DAA520" // overrides theme colors with whatever color value you want
                }}
                locale={locale}
                height='550px'
                width={'60vw'}
                onChange={e => {
                  // console.log(e);
                  !e.error && set_investment_form_amounts(JSON.stringify(e.jsObject));
                }}
              />
            </Panel>
          </Accordion>
        </div>

      </Cell>
      <Modal
        onClose={() => onCloseEmailTest()}
        closeable
        isOpen={isEmailModalOpen}
        animate
        autoFocus
        size={SIZE.default}
        role={ROLE.dialog}
      >
        <ModalHeader>Email Test</ModalHeader>
        <Grid overrides={{
          Grid: { style: { padding: '1.3rem !important' } },
        }}>
          <Cell
            span={12}
            overrides={{
              Cell: { style: { padding: '0px !important' } },
            }}
          >
            <LabelMedium
              style={{
                marginBottom: '16px',
              }}
            >
              Test Email
            </LabelMedium>
            <Input
              value={email}
              onChange={e => {
                setEmail(e.target.value)
              }}
              placeholder="Enter a email"
              clearOnEscape
            />
          </Cell>
        </Grid>
        <ModalFooter>
          <ModalButton kind={ButtonKind.tertiary} onClick={() => onCloseEmailTest()}>
            Cancel
          </ModalButton>
          <ModalButton onClick={() => onSendEmail()} isLoading={isEmailLoading}>Send</ModalButton>
        </ModalFooter>
      </Modal>
    </Grid>
  );
};

export default GeneralSettings;
